<template>
  <div class="login_bgimg flex-align-center" v-if="iponeStatus">
    <div class="loginbox flex-layout">
      <div class="login_logo">
        <div>
          <img src="@/assets/login/logo1.png" />
          <img src="@/assets/login/logo2.png" />
        </div>
      </div>
      <div class="login_verify flex-align flex-column">
        <div class="login_title">欢迎登录</div>
        <ul class="login_verify_li" v-if="QRcodeshow1">
          <!-- <li>欢迎登录</li> -->
          <li></li>
          <!-- v-show="false" -->
          <li class="flex-align-between">
            <span @click="login_cut('pass')" :class="{ active: login_show == 'pass' }">密码登录</span>
            <span>|</span>
            <span @click="login_cut('verify')" :class="{ active: login_show == 'verify' }">验证码登录</span>
          </li>
          <li>
            <div v-show="login_show == 'pass'">
              <!-- 登录 -->

              <el-form :model="numberValidateForm" :rules="rules" ref="numberValidateForm0" class="demo-ruleForm">
                <el-form-item prop="user">
                  <el-input type="text" v-model="numberValidateForm.user" autocomplete="off" placeholder="请输入教师手机号或用户名"
                    maxlength="11" @blur="modelIpone">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>

                <el-form-item prop="password">
                  <el-input type="password" v-model="numberValidateForm.password" autocomplete="off"
                    :show-password="true" placeholder="请输入登录密码" maxlength="11">
                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  </el-input>
                </el-form-item>
              </el-form>
              <div class="flex-align-between accessory">
                <span class="login_checkbox">
                  <el-checkbox v-model="checked">记住密码</el-checkbox>
                </span>
                <!-- v-show="false" -->
                <span @click="centerDialogVisible = true">忘记密码？</span>
              </div>
            </div>
            <div v-show="login_show == 'verify'">
              <el-form :model="numberValidateForm" ref="numberValidateForm" class="demo-ruleForm" :rules="rules">
                <el-form-item prop="phoneid">
                  <el-input type="text" v-model="numberValidateForm.phoneid" autocomplete="off" placeholder="请输入教师手机号"
                    maxlength="11">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="phonecode">
                  <el-input type="text" v-model="numberValidateForm.phonecode" autocomplete="off" placeholder="请输入验证码"
                    maxlength="6" @input="codeput">
                  </el-input>
                  <img src="@/assets/login/yanzhengma.png" class="verify_img" />
                  <div class="verify_text">
                    <span @click="Verification ? gaincode(60000) : ''" v-if="Verification">获取验证码</span>
                    <span v-else>{{ timer }}s</span>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </li>

          <li>
            <div class="loginbtn flex-align-center" @click="submitForm('numberValidateForm')">
              登录
            </div>
          </li>
          <li class="flex-align-center">
            <img src="../../assets/login/QRcode.png" @click="
                QRcodeshow1 = false;
                create();
              " />
            <span @click="
                QRcodeshow1 = false;
                create();
              ">二维码登录</span>
          </li>
        </ul>
        <ul class="login_verify_qrcode" v-else>
          <li>
            <vue-qr :text="Top_up_balance" :size="300" :logoScale="0.3"></vue-qr>
          </li>
          <li class="flex-align-center">
            <img src="../../assets/login/QRcodeh.png" />
            <span @click="response">请使用微信扫一扫</span>
          </li>
          <li class="flex-align-center" @click="
              QRcodeshow1 = true;
              goback();
            ">
            返回
          </li>
        </ul>
        <!-- 忘记密码弹窗 -->
        <div class="forget_dialog">
          <el-dialog title="忘记密码" :visible.sync="centerDialogVisible">
            <el-form :model="forget_password" ref="numberValidateForm2" :rules="rules" label-width="100px"
              class="demo-ruleForm flex-column-center">
              <el-form-item prop="ipone_id">
                <el-input type="text" v-model="forget_password.ipone_id" autocomplete="off" placeholder="请输入手机号"
                  maxlength="11"></el-input>
              </el-form-item>
              <el-form-item prop="verify_code">
                <el-input type="text" v-model="forget_password.verify_code" autocomplete="off" placeholder="请输入验证码"
                  maxlength="6" @input="codeput">
                </el-input>
                <div class="verify_text2">
                  <span @click="acquireCode(60000)" v-if="VerificationR">获取验证码</span>
                  <span v-else>{{ timeR }}s</span>
                </div>
              </el-form-item>
              <el-form-item prop="new_password">
                <el-input type="password" v-model="forget_password.new_password" autocomplete="off" placeholder="请输入新密码"
                  maxlength="11" :show-password="true"></el-input>
              </el-form-item>
              <el-form-item prop="news_password">
                <el-input type="password" v-model="forget_password.news_password" autocomplete="off"
                  placeholder="请再次输入密码" maxlength="11" :show-password="true">
                  <!-- @blur="plural" -->
                  <!-- @blur="plural" -->
                </el-input>
              </el-form-item>
              <el-form-item>
                <div class="amendbtn flex-align-center" @click="resetPwd">
                  确认修改
                </div>
              </el-form-item>
            </el-form>
          </el-dialog>
        </div>
        <!-- 忘记密码弹窗 -->
      </div>
      <!-- 登录状态提示 -->
      <div class="logging_status">
        <subscribe :dialogVisible="centerDialogVisible2" @clear="clear" @sure="sure"></subscribe>
      </div>
      <!-- 登录状态提示 -->
    </div>
    <!-- <loading class="load" v-if="loadShow"></loading> -->
    <div class="loadbg flex-align" v-if="scheduleShow">
      <div class="loadbgbox">
        <el-progress :percentage="schedule" color="#44978B" :format="format"></el-progress>
      </div>
    </div>
  </div>
  <div class="phone" v-else>
    <div class="phoneLogo">
      <img src="../../assets/login/phone/lg.png" />
    </div>
    <div class="phoneForm">
      <div class="from">
        <ul class="login_verify_li" v-if="QRcodeshow1">
          <li class="flex-align-between">
            <span @click="login_cut('pass')" :class="{ active: login_show == 'pass' }">密码登录</span>
            <span>|</span>
            <span @click="login_cut('verify')" :class="{ active: login_show == 'verify' }">验证码登录</span>
          </li>
          <li>
            <div v-show="login_show == 'pass'">
              <el-form :model="numberValidateForm" :rules="rules" ref="numberValidateForm0" class="demo-ruleForm">
                <el-form-item prop="user">
                  <el-input type="text" v-model="numberValidateForm.user" autocomplete="off" placeholder="请输入教师手机号或用户名"
                    maxlength="11" @blur="modelIpone">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>

                <el-form-item prop="password">
                  <el-input type="password" v-model="numberValidateForm.password" autocomplete="off"
                    :show-password="true" placeholder="请输入登录密码" maxlength="11">
                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <div v-show="login_show == 'verify'">
              <el-form :model="numberValidateForm" ref="numberValidateForm" class="demo-ruleForm" :rules="rules">
                <el-form-item prop="phoneid">
                  <el-input type="text" v-model="numberValidateForm.phoneid" autocomplete="off" placeholder="请输入教师手机号"
                    maxlength="11">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="phonecode">
                  <div class="flex-align-between VerificationCode">
                    <el-input type="text" v-model="numberValidateForm.phonecode" autocomplete="off" placeholder="请输入验证码"
                      maxlength="6" @input="codeput">
                    </el-input>
                    <img src="@/assets/login/phone/VerificationCode.png" class="verify_img" />
                    <div class="verify_text">
                      <span class="flex-align-center" @click="Verification ? gaincode(60000) : ''"
                        v-if="Verification">获取验证码</span>
                      <span class="flex-align-center" v-else>{{ timer }}s</span>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </li>

          <li>
            <div class="loginbtn flex-align-center" @click="submitForm('numberValidateForm')">
              登录
            </div>
          </li>
        </ul>
      </div>
      <div class="illusory"></div>
    </div>
    <div class="iponestatus">
      <!-- centerDialogVisible2 -->
      <!-- {{hint}} -->
      <el-dialog title="提示" :visible="centerDialogVisible2" center :show-close="false">
        <div class="spans flex-align-center">{{hint}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearipone">知道了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import subscribe from "../../Common_components/Popup/subscribe";
  import vueQr from "vue-qr";
  import loading from "../../Common_components/Popup/loading/loading";
  import {
    mapState,
    mapMutations
  } from "vuex";
  import {
    Encrypt
  } from '../../assets/js/crypto.js'
  export default {
    components: {
      subscribe,
      vueQr,
      loading,
    },
    data() {
      //密码登录
      var validateUser = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("手机号或用户名不能为空"));
        } else {
          // console.log(1111)

          callback();
        }
      };
      var validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入登录密码"));
        } else {
          callback();
        }
      };
      //密码登录
      //验证码登录
      var iponeuser = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else {
          if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号"));
          }
        }
      };
      var iponecodes = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      };
      //忘记密码
      var forget_user = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("手机号不能为空"));
        } else {
          if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号"));
          }
        }
      };
      var forget_code = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      };
      var forget_newpass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入新密码"));
        } else {
          callback();
        }
      };
      var forget_newspass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else {
          if (
            this.forget_password.news_password !=
            this.forget_password.new_password
          ) {
            callback(new Error("密码输入不一致"));
          }
        }
      };
      //忘记密码
      return {
        loadShow: true,
        backgroundImage: "url(" + require("../../assets/login/login_bg.png") + ")",
        checked: false,
        login_show: "pass",
        centerDialogVisible: false,
        centerDialogVisible2: false,
        message_data: "",
        Verification: true,
        VerificationR: true,
        timer: 60,
        lastTime: 0,
        timeR: 60,
        lastTimeR: 0,
        numberValidateForm: {
          user: "",
          password: "",
          pc_info: "",
          phoneid: "",
          phonecode: "",
        },
        forget_password: {
          ipone_id: "",
          verify_code: "",
          new_password: "",
          news_password: "",
        },
        rules: {
          user: [{
            required: true,
            validator: validateUser,
            trigger: "blur",
          }, ],
          password: [{
            required: true,
            validator: validatePass,
            trigger: "blur",
          }, ],
          phoneid: [{
            required: true,
            validator: iponeuser,
            trigger: "blur",
          }, ],
          phonecode: [{
            required: true,
            validator: iponecodes,
            trigger: "blur",
          }, ],

          ipone_id: [{
            required: true,
            validator: forget_user,
            trigger: "blur",
          }, ],
          verify_code: [{
            required: true,
            validator: forget_code,
            trigger: "blur",
          }, ],
          new_password: [{
            required: true,
            validator: forget_newpass,
            trigger: "blur",
          }, ],
          news_password: [{
            required: true,
            validator: forget_newspass,
            trigger: "blur",
          }, ],
        },
        QRcodeshow1: true,
        timers: "",
        Top_up_balance: "",
        scheduleShow: false,
        schedule: 0,
        loadTime: "",
        shareStatus: false,
        screenWidth: "",
        iponeStatus: true,
        hint: "",
      };
    },
    created() {
      // this.numberValidateForm.user = '18800000001';
      // this.numberValidateForm.password = '123456';
      // this.submitForm();
    },
    mounted() {
      //获取当前分辨率
      // console.log("this.screenWidth", document.body.clientWidth);
      if (document.body.clientWidth < 600) {
        this.iponeStatus = false;
      } else {
        this.iponeStatus = true;
      }
      // alert(document.body.clientWidth);
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
          if (document.body.clientWidth < 600) {
            this.iponeStatus = false;
          } else {
            this.iponeStatus = true;
          }
          console.log("this.screenWidth", this.screenWidth);
        })();
      };

      this.getCookie();
      if (this.$route.query.ticket != undefined) {
        this.parameter();
      }
      if (this.$route.query.redirect_uri != undefined) {
        this.DirectUrl();
      }
      if (this.$route.query.token != undefined) {
        sessionStorage.token = this.$route.query.token;
        this.$router.push("/home");
      }
      if (!this.$route.params.type) {
        this.load();
      }
    },
    methods: {
      format(percentage) {
        return `正在加载中${percentage}%`;
      },
      loadclose() {
        setTimeout(() => {
          this.scheduleShow = false;
        }, 1000);
      },
      load() {
        let _that = this;
        this.scheduleShow = true;
        this.loadTime = setInterval(() => {
          _that.schedule++;
          // console.log('加载中！', _that.schedule)
          if (document.readyState === "complete") {
            _that.schedule = 100;
            clearInterval(_that.loadTime);
            this.loadclose();
          }
        }, 2000);
      },
      ...mapMutations(["hint_data"]),

      modelIpone() {
        if (/^1[3456789]\d{9}$/.test(this.numberValidateForm.user)) {
          // console.log('this.numberValidateForm.user', this.numberValidateForm.user)
          this.numberValidateForm.phoneid = this.numberValidateForm.user;
        }
      },
      //计时器监视扫码反馈
      create() {
        let _this = this;
        _this.$nextTick(() => {
          const randoms = Math.round(Math.random() * 10000);
          this.qrcode(randoms);
          this.timers = window.setInterval(() => {
            setTimeout(() => {
              this.response(randoms);
            }, 0);
          }, 500);
        });
      },
      // 生成二维码
      qrcode(randoms) {
        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx926a53188b215464&redirect_uri=https://api.lezhireading.com/api/cnuzi/pc/qr_code/login/redirect&response_type=code&scope=snsapi_base&state=" +
          randoms +
          "#wechat_redirect";
        this.Top_up_balance = url;
      },
      // 扫码登录响应
      async response(randoms) {
        let data = {
          state: randoms,
        };
        let resData = await this.$Api.Home.qrCode(data);
        if (resData.code == 200) {
          clearInterval(this.timers);
          sessionStorage.token = resData.data.token;
          this.$router.push("/home");
        }
      },
      // 扫码登录响应
      goback() {
        clearInterval(this.timers);
      },
      UploadMachineCode(machinecode) {
        this.numberValidateForm.pc_info = machinecode;
      },
      login_cut(type) {
        this.$refs.numberValidateForm.resetFields();
        this.login_show = type;
      },
      //验证码
      codeput() {
        if (!/^[0-9]+.?[0-9]*$/.test(this.numberValidateForm.phonecode)) {
          this.numberValidateForm.phonecode = "";
        }
        if (!/^[0-9]+.?[0-9]*$/.test(this.forget_password.verify_code)) {
          this.forget_password.verify_code = "";
        }
      },
      async gaincode(intervalTime) {
        console.log("获取验证码");
        let now = new Date().valueOf();
        if (this.lastTime == 0 || now - this.lastTime > intervalTime) {
          this.lastTime = now;
        } else {
          // console.log('跳出', this.lastTime);
          return false;
        }
        // console.log('now', now)
        // 判断身份
        if (
          this.numberValidateForm.phoneid == "" ||
          !/^1[3456789]\d{9}$/.test(this.numberValidateForm.phoneid)
        ) {
          // console.log('11111111111111111')
          // alert('请输入手机号')
          this.centerDialogVisible2 = true;
          this.hint_data("请输入手机号");
          this.hint = "请输入手机号";
          return false;
        }
        // if (this.timer) {
        //   return false;
        // }
        //判断是否注册存在
        let datareg = {
          mobile: this.numberValidateForm.phoneid,
        };
        let resDatareg = await this.$Api.Login.regPhone(datareg);
        // console.log('resData.data.msg', resDatareg.data.msg);

        if (resDatareg.data.msg == "手机号码并不存在") {
          this.centerDialogVisible2 = true;
          this.hint_data("该手机号未注册");
          this.hint = "该手机号未注册";
          this.lastTime = 0;
          return false;
        }
        //判断是否是老师身份
        let datatea = {
          phone: this.numberValidateForm.phoneid,
        };
        let resDatatea = await this.$Api.Login.isteacher(datatea);
        if (resDatatea.data.result == false) {
          this.lastTime = 0;
          this.centerDialogVisible2 = true;
          this.hint_data("用户角色不是教师");
          this.hint = "用户角色不是教师";
          return false;
        }
        //获取验证码
        let dataCode = {
          app_key: "rd_00003",
          mobile: this.numberValidateForm.phoneid,
          mobile_rule: "mobile_required",
          secret_key: "NGm3glIO38j7tlBi1Z0XX5EvAFqSjCqLf3UezQdCz9VzNoTUbGqlTICIHwzaK4li",
        };
        this.$Api.Login.getPhoneCode(dataCode)
          .then((res) => {
            if (res.code == 400) {
              this.centerDialogVisible2 = true;
              this.hint_data(res.data.msg);
              this.hint = res.data.msg;
              this.lastTime = 0;
            } else {
              this.Verification = false;
              let auth_timer = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                  this.Verification = true;
                  clearInterval(auth_timer);
                  this.timer = 60;
                  this.lastTime = 0;
                }
              }, 1000);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });

        //倒计时
      },
      async submitForm(formName) {
        //判断登录类别
        if (this.login_show == "pass") {
          // console.log('密码登录')
          if (
            this.numberValidateForm.user == "" ||
            this.numberValidateForm.password == ""
          ) {
            this.$refs.numberValidateForm0.validate();
            return false;
          }

          const self = this;
          let machinecode;
          if (window.electronJoinClass) {
            machinecode = "tc";
          } else {
            machinecode = "pc";
          }
          // let machinecode = (this.numberValidateForm.pc_info =
          //   localStorage.getItem("machinecode"));
          // if (machinecode == null) {
          //   machinecode = "E7739A5C0E505E008A52DC0F2497D6BB";
          // }

          let resData;
          let boliwei;
          if (this.$route.query.ticket != undefined) {
            let data = {
              url: window.location.href,
            };
            resData = await this.$Api.Home.getThirdAuthUserInfo(data);
          } else {
            let data = {
              phone: this.numberValidateForm.user,
              // password: Encrypt(this.numberValidateForm.password),
              password: this.numberValidateForm.password,
              deviceInfo: machinecode,
            };
            let data2 = {
              phone: this.numberValidateForm.user,
              // password: Encrypt(this.numberValidateForm.password),
              password: this.numberValidateForm.password,
            };
            resData = await this.$Api.Home.login(data);

            boliwei = await this.$Api.Home.login2(data2);
            if (boliwei.code === 200) {
              sessionStorage.tokenVideo = boliwei.data.access_token;
            }
            console.log("boliwei", boliwei);
          }
          if (resData.code === 200) {
            sessionStorage.token = resData.data.access_token;
            sessionStorage.name = this.numberValidateForm.user;

            if (this.shareStatus) {
              this.DirectUrl();
            } else {
              this.$cookies.set("token", resData.data.access_token, 60 * 60 * 2);
              // if (window.electronJoinClass) {
              if (window.electronJoinClass) {
                this.$router.push("/livePanel");
              } else {
                this.$router.push("/home");
              }
            }
          } else if (resData.code === 500) {
            if (resData.msg == "设备数量达到上限") {
              this.centerDialogVisible2 = true;
              this.hint_data(
                "该账号已绑定5台设备,如需更改设备请联系客服010-885129480"
              );
              this.hint =
                "该账号已绑定5台设备,如需更改设备请联系客服010-885129480";
            } else {
              this.hint_data(resData.msg);
              this.hint = resData.msg;
              this.centerDialogVisible2 = true;
            }
          }
          if (self.checked == true) {
            self.setCookie(
              self.numberValidateForm.user,
              self.numberValidateForm.password,
              7
            );
          } else {
            self.clearCookie();
          }
        } else {
          if (
            this.numberValidateForm.phoneid == "" ||
            this.numberValidateForm.phonecode == ""
          ) {
            this.$refs.numberValidateForm.validate();
            return false;
            // this.centerDialogVisible2 = true;
            // this.hint_data(
            //   '手机号不能为空'
            // );
            // return false;
          }
          // else if (this.numberValidateForm.phonecode == '') {
          //   this.centerDialogVisible2 = true;
          //   this.hint_data(
          //     '验证码不能为空'
          //   );
          //   return false;
          // }

          // console.log('验证码登录');
          let data = {
            app_key: "rd_00003",
            code: this.numberValidateForm.phonecode,
            device_type: "Android",
            device_uid: "295a4fbf716094ee",
            phone: this.numberValidateForm.phoneid,
            role: "teacher",
            secret_key: "NGm3glIO38j7tlBi1Z0XX5EvAFqSjCqLf3UezQdCz9VzNoTUbGqlTICIHwzaK4li",
          };
          this.$Api.Login.userslogincode(data)
            .then((res) => {
              if (res.code == 200) {
                // console.log('登录信息', res.data.token)
                sessionStorage.token = res.data.token;
                this.$router.push("/home");
              } else {
                this.centerDialogVisible2 = true;
                this.hint_data(res.data.msg);
                this.hint = res.data.msg;
                //  console.log('报错', res.data.msg)
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      },
      // 有登录订单参数
      async parameter() {
        let data = {
          url: window.location.href,
        };
        console.log("data", this.$router);
        let resData = await this.$Api.Home.getThirdAuthUserInfo(data);
        if (resData.code === 200) {
          sessionStorage.token = resData.data.access_token;
          sessionStorage.name = this.numberValidateForm.user;
          this.$router.push("/home");
        } else if (resData.code === 500) {
          if (resData.msg == "设备数量达到上限") {
            this.centerDialogVisible2 = true;
            this.hint_data(
              "该账号已绑定5台设备,如需更改设备请联系客服010-885129480"
            );
          } else {
            this.hint_data(resData.msg);
            this.centerDialogVisible2 = true;
          }
        }
      },
      async DirectUrl() {
        // debugger
        // console.log('this.$cookies.get(token)', this.$cookies.get('token'))
        sessionStorage.token = this.$cookies.get("token");
        let url = window.location.href;
        let data = {
          url: url.split("redirect_uri=")[1],
        };
        let resData = await this.$Api.Home.getDirectUrl(data);
        console.log("++++++", resData);
        if (resData.code === 200) {
          window.location = resData.data;
        } else {
          this.shareStatus = true;
        }
      },

      // 无登录订单参数
      // 设置cookie
      setCookie(c_name, c_pwd, exdays) {
        var exdate = new Date(); //获取时间
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
        // console.log('exdate',exdate)
        // console.log('exdate.toGMTString()',exdate.toGMTString())
        window.document.cookie =
          "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
        window.document.cookie =
          "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
      },
      // 设置cookie
      // 读取cookie
      getCookie: function () {
        if (document.cookie.length > 0) {
          // console.log('document.cookie',document.cookie)
          var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
          // console.log('arr',arr)
          for (var i = 0; i < arr.length; i++) {
            var arr2 = arr[i].split("="); //再次切割
            //判断查找相对应的值
            if (arr2[0] == "userName") {
              this.numberValidateForm.user = arr2[1]; //保存到保存数据的地方
              //  console.log('账号',this.numberValidateForm.user)
              this.checked = true;
            } else if (arr2[0] == "userPwd") {
              this.numberValidateForm.password = arr2[1];
              this.checked = true;
              // console.log('密码',this.numberValidateForm.password)
            }
          }
        }
      },
      // 读取cookie
      // 清除cookie
      clearCookie: function () {
        this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
      },
      // 清除cookie
      clear(bool) {
        this.centerDialogVisible2 = bool;
      },
      clearipone() {
        this.centerDialogVisible2 = false;
      },
      sure(bool) {
        this.centerDialogVisible2 = bool;
      },
      //重置密码
      async acquireCode(intervalTime) {
        let now = new Date().valueOf();
        if (this.lastTimeR == 0 || now - this.lastTimeR > intervalTime) {
          this.lastTimeR = now;
        } else {
          // console.log('跳出', this.lastTimeR);
          return false;
        }
        if (
          this.forget_password.ipone_id == "" ||
          !/^1[3456789]\d{9}$/.test(this.forget_password.ipone_id)
        ) {
          return false;
        }
        //判断是否注册存在
        let datareg = {
          mobile: this.forget_password.ipone_id,
        };
        let resDatareg = await this.$Api.Login.regPhone(datareg);
        // console.log('resData.data.msg', resDatareg.data.msg);
        if (resDatareg.data.msg == "手机号码并不存在") {
          this.centerDialogVisible2 = true;
          this.hint_data("该手机号未注册");
          this.lastTimeR = 0;
          return false;
        }
        //判断是否是老师身份
        let datatea = {
          phone: this.forget_password.ipone_id,
        };
        let resDatatea = await this.$Api.Login.isteacher(datatea);
        // console.log('resDatatea', resDatatea)
        if (resDatatea.data.result == false) {
          this.lastTimeR = 0;
          this.centerDialogVisible2 = true;
          this.hint_data("用户角色不是教师");
          return false;
        }
        //获取验证码
        let dataCode = {
          app_key: "rd_00003",
          mobile: this.forget_password.ipone_id,
          mobile_rule: "mobile_required",
          secret_key: "NGm3glIO38j7tlBi1Z0XX5EvAFqSjCqLf3UezQdCz9VzNoTUbGqlTICIHwzaK4li",
        };
        this.$Api.Login.getPhoneCode(dataCode)
          .then((res) => {
            if (res.code == 400) {
              this.centerDialogVisible2 = true;
              this.hint_data(res.data.msg);
              this.lastTimeR = 0;
            } else {
              this.VerificationR = false;
              let auth_timer = setInterval(() => {
                this.timeR--;
                if (this.timeR <= 0) {
                  this.VerificationR = true;
                  clearInterval(auth_timer);
                  this.timeR = 60;
                  this.lastTime = 0;
                }
              }, 1000);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
        // console.log('resDataCode', resDataCode);
        //倒计时
      },
      resetPwd() {
        if (
          this.forget_password.ipone_id == "" ||
          this.forget_password.verify_code == "" ||
          this.forget_password.new_password != this.forget_password.news_password
        ) {
          this.$refs.numberValidateForm2.validate();
          return false;
          // this.centerDialogVisible2 = true;
          // this.hint_data(
          //   '手机号不能为空'
          // );
          // return false;
        }
        // else if (this.forget_password.verify_code == '') {
        //   this.centerDialogVisible2 = true;
        //   this.hint_data(
        //     '验证码不能为空'
        //   )
        //   return false;
        // };
        //  this.$refs.numberValidateForm.validate();
        //      return false;
        if (
          this.forget_password.news_password === this.forget_password.new_password
        ) {
          let data = {
            mobile: this.forget_password.ipone_id,
            password: this.forget_password.news_password,
            verifyCode: this.forget_password.verify_code,
          };
          this.$Api.Login.resetPwd(data)
            .then((res) => {
              this.centerDialogVisible2 = true;
              if (res.code == 200) {
                this.centerDialogVisible = false;
                this.hint_data("修改成功，请重新登录");
              } else {
                this.hint_data(res.data.msg);
              }
              // console.log('res', res)
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      },
    },
    watch: {
      login_show(newValue, oldValue) {
        if (newValue == "verify") {
          if (/^1[3456789]\d{9}$/.test(this.numberValidateForm.user)) {
            this.numberValidateForm.phoneid = this.numberValidateForm.user;
          }
        }
      },
    },
  };
</script>

<style lang="less">
  @import "less/logins.less";
</style>
<style>
  .iponestatus .el-dialog {
    width: 1300px;
    border-radius: 0.2rem;
  }

  .iponestatus .el-dialog__header {
    /* padding: 0.3rem; */
    font-weight: bold;
    /* font-size: 30px!important; */
    /* font-size: ; */
  }

  .iponestatus .el-dialog__title {
    /* padding: 0.3rem; */
    /* font-weight: bold; */
    font-size: 80px;
    line-height: inherit !important;
    /* font-size: ; */
  }

  .iponestatus .spans {
    text-align: center;
    min-height: 1.5rem;
    font-size: 80px;
  }

  .iponestatus .el-button:hover {
    background-color: #fff;
    color: #21bd99;
    border-color: #21bd99;
  }

  .iponestatus button {
    width: 1.5rem;
    height: 0.6rem;
    background-color: #21bd99 !important;
    color: #fff !important;
    font-size: 80px;
    margin-bottom: 0.5rem;
  }

  /* 框 */
  .login_checkbox .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #21bd99;
  }

  .login_checkbox .el-checkbox__label {
    color: #b5b5b5;
    font-size: 17px;
  }

  .login_checkbox .el-checkbox__inner {
    width: 19px;
    height: 19px;
  }

  .login_checkbox .el-checkbox__inner::after {
    height: 12px;
    left: 7px;
  }

  .login_checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
  .login_checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #21bd99;
    border-color: #21bd99;
  }

  .login_checkbox .el-checkbox__inner:hover {
    border-color: #21bd99;
  }

  .login_checkbox .el-checkbox__input .is-focus .el-checkbox__inner {
    border-color: #21bd99;
  }

  /* 框 */
</style>